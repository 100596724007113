import React, {useState, useEffect, useRef} from 'react'
import './comparatore.css';
import { FaArrowRight, FaGraduationCap } from 'react-icons/fa';
import comp1 from '../imgs/comp1.png';
import {useNavigate} from 'react-router-dom'
import dataCorsi from './output.json';
import icon from '../imgs/Vector.png';
import { useSearch } from '../context/SearchContext';
import compmob1 from '../imgs/comp-mob1.png';
import axios from 'axios';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

const PrezziSlider = ({ min, max, step, onChange, rangeMax }) => {
    const [value, setValue] = useState([min, rangeMax]);
  
    const handleSliderChange = (newValue) => {
      setValue([min, newValue[1]]);
      onChange([min, newValue[1]]);
    };
  
    return (
      <div className='barra-prezzi' id='price-slider'>
        <div id='price-display' className='price-range-max'  style={{ position: 'relative', left: `${20.5714 + (((value[1] - 3000) / 500) * 7)}%` }}>
            {value[1]}€
          </div>
        <Slider
          id='budget-range'
          min={min}
          max={max}
          step={step}
          range
          value={value}
          onChange={handleSliderChange}
          className="custom-slider-prezzo"
        />
      </div>
    );
  };

const ComparatoreLeadSystem = () => {
    const { degreeType, setDegreeType, desiredDegree, setDesiredDegree, subjectOfInterest, setSubjectOfInterest, 
        budget, setBudget, lastName, setLastName, firstName, setFirstName, email, setEmail, phone, setPhone, enrollmentTime,
        setEnrollmentTime, universityStatus, setUniversityStatus, workStatus, setWorkStatus, studyTime, setStudyTime, categories,
        setCategories, origBudget, setOrigBudget, origDegreeType, setOrigDegreeType, origDesiredDegree, setOrigDesiredDegree, origSubjectOfInterest, setOrigSubjectOfInterest
      , rangeMax, setRangeMax } = useSearch();

    const navigate = useNavigate();
    const [accept, setAccept] = useState(false);
    const [acceptTreatment, setAcceptTreatment] = useState(false);
    const [utmCampaign, setUtmCamp] = useState("");
    const [utmSource, setUtmSrc] = useState("");
    const [utmMedium, setUtmMedium] = useState("");
    const [utmTerm, setUtmTerm] = useState("");
    const [utmAdset, setUtmAdset] = useState("");
    const [utmAdgroup, setUtmAdgroup] = useState("");
    const storedLead = localStorage.getItem('lead');
    if (storedLead !== null) {
      const leadData = JSON.parse(storedLead);
    } else {
      console.log("Nessun dato lead trovato in localStorage.");
    }

    const prezzoMin = 1000;
    const [prezzoMax, setPrezzoMax] = useState(3000);
  
    const handleChangePrezzo = (nuoviValori) => {
      setPrezzoMax(nuoviValori[1]);
      setRangeMax(nuoviValori[1]);
    };

    useEffect(() => {
        const currentUrl = window.location.href;
    
        const searchParams = new URLSearchParams(currentUrl);
    
        const utmSource = searchParams.get('utm_source');
        const utmCampaign = searchParams.get('utm_campaign');
        const utmContent = searchParams.get('utm_medium');
        const utmTerm = searchParams.get('utm_term');
        const utmAdgroup = searchParams.get("utm_adset");
        const utmAdset = searchParams.get("utm_ad");
    
        setUtmCamp(utmCampaign);
        setUtmSrc(utmSource);
        setUtmMedium(utmContent);
        setUtmTerm(utmTerm)
        setUtmAdset(utmAdset);
        setUtmAdgroup(utmAdgroup);

        console.log(utmSource, utmContent, utmCampaign, utmTerm, utmAdgroup, utmAdset);
        setRangeMax(3000)
      }, []);
  
    const handleChange = (e, setter) => {
      setter(e.target.value);
    };
    const handleUniversityStatusChange = (e) => {
        setUniversityStatus(e);
      };
      const [handlePosition, setHandlePosition] = useState(-110);
    
      const handleWorkStatusChange = (e) => {
        setWorkStatus(e);
      };

      const handleSendSheet = async () => {
        const urlSheet = 'https://sheet.best/api/sheets/2b48a2f8-bc37-4c32-9927-390557b57bd2';
        const checkUrl = `${urlSheet}?search=email:${email}`; 
        const formData = {
          Data: new Date(),
          nome: firstName,
          cognome: lastName,
          email: email,
          ["Percorso di studi"]: desiredDegree,
          ["Campagna UTM"]: utmCampaign,
          ["Source UTM"]: utmSource,
          ["Content UTM"]: utmMedium,
          Cellulare: phone,
          ["Keywords UTM"]: utmTerm,
          ["Adgroup UTM"]: utmAdgroup,
          ["Adset UTM"]: utmAdset,
        };

        const checkResponse = await fetch(checkUrl, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          });
      
          const existingData = await checkResponse.json();
          const matchingData = existingData.filter(item => item.email === email);

          if (matchingData.length > 0) {
            console.log('I dati con questa email esistono già. Non è possibile inviare duplicati.', matchingData);
            //navigate('/universita/risultati', { state: { showLoad: true }});
            return; 
          }
      
        await fetch(urlSheet, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        })
          .then((response) => {
      
            if (response.ok) {
              console.log("Dati inviati con successo");
              setIsLoad(false);
              navigate('/universita/risultati', { state: { showLoad: true }});
            } else {
              console.error("Errore nell'invio dei dati");
            }
          })
          .catch((error) => {
            console.error("Errore:", error);
          });
      };

      const [isLoad, setIsLoad] = useState(false);

      const handleSendLead = async () => {

        const formData = {
            firstName: firstName,
            lastName: lastName,
            email: email,
            phone: phone,
            lavoro: workStatus,
            universita: universityStatus,
            orario: studyTime,
            corsoDiLaurea: desiredDegree,
            facolta: subjectOfInterest,
            utmCampaign: utmCampaign,
            utmSource: utmSource,
            utmContent: utmMedium,
            utmTerm: utmTerm,
            categories: categories,
            enrollmentTime: enrollmentTime,
            budget: rangeMax.toString(),
            utmAdgroup: utmAdgroup,
            utmAdset: utmAdset,
            tipologiaCorso: degreeType,
        }
       //https://leadsystem-production.up.railway.app/api/lead-compara-corsi
       //http://localhost:8000/api/lead-compara-corsi
        try {
          const response = await axios.post('https://leadsystemfunnel-production.up.railway.app/api/lead-compara-corsi', formData);
          console.log('Risposta dal server:', response.data);
          localStorage.setItem('lead', JSON.stringify(formData))
        } catch (error) {
          console.error('Errore durante la richiesta al server:', error);
        }
      };

      const [missingField, setMissingField] = useState([]);

      const checkFields = () => {
        let missingField = []
        if (firstName === "") missingField.push("Nome");
        if (lastName === "") missingField.push("Cognome");
        if (email === "") missingField.push("Email");
        if (phone === "") missingField.push("Numero di telefono");
        if (categories === "") missingField.push("Categorie");
        if (studyTime === "") missingField.push("Tempo di studio");
        if (workStatus === "") missingField.push("Stato lavorativo");
        if (universityStatus === "") missingField.push("Stato universitario");
        if (enrollmentTime === "") missingField.push("Tempo di iscrizione");
        if (subjectOfInterest === "") missingField.push("Oggetto di interesse");
        if (desiredDegree === "") missingField.push("Corso di laurea");
        if (degreeType === "") missingField.push("Tipo di laurea");
        if (!accept) missingField.push("Accetta i termini e le condizioni");
    
        if (missingField.length > 0) {
            setMissingField(missingField);
            alert(`Compila i campi: ${missingField.join(", ")}`);
            setIsLoad(false);
            return false;
        }
    
        return true;
    };
      const handleSubmit = () => {
        setIsLoad(true)
        if (!checkFields()) {
          return;
        } else if (!isValidMobileNumber(phone)){
            alert('Inserisci un numero valido');
            setMissingField("Numero di telefono");
            setIsLoad(false);
            setPhone("");
            return
        } else if (!isValidEmail(email)){
            alert('Inserisci una mail valida');
            setMissingField("Email");
            setIsLoad(false);
            setEmail("");
            return
        }
        try {
            handleSendLead();
            handleSendSheet()
            navigate('/universita/risultati', { state: { showLoad: true }});
            //window.scrollTo(0,0);
        } catch (error) {
           console.error(error); 
           setIsLoad(false)
        }
      };

      const [uniqueArea, setUniqueArea] = useState([]);
      const [uniqueCorso, setUniqueCorso] = useState([]);
      const [uniquePrice, setUniquePrice] = useState([]);

      useEffect(() => {
        const filteredAreaNames = [...new Set(dataCorsi.filter(data => data.Tipologia === degreeType).map(data => data.Area))];
        setUniqueArea(filteredAreaNames);
        setDesiredDegree("");
        setSubjectOfInterest("");
        setBudget("");
      }, [degreeType]);

      useEffect(() => {
        const filteredCorsoNames = [...new Set(dataCorsi.filter(data => data.Tipologia === degreeType && data.Area === desiredDegree).map(data => data['Corsi di laurea + (non lo so)']))];
        setUniqueCorso(filteredCorsoNames)
        setSubjectOfInterest("");
        setBudget("");
      }, [desiredDegree]);

      useEffect(() => {
        const filteredBudgetNames = [...new Set(dataCorsi.filter(data => data.Tipologia === degreeType && data.Area === desiredDegree).map(data => data['Costo ']))];
        setUniquePrice(filteredBudgetNames);
        setBudget("");
      }, [subjectOfInterest]);

      const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      
        return emailRegex.test(email);
      };

      const isValidMobileNumber = (phoneNumber) => {
        const mobileNumberRegex = /^[0-9]{10}$/;
      
        return mobileNumberRegex.test(phoneNumber);
      };

      const isMobile = () => {
        return window.innerWidth <= 768;
      };

      const [showFullText, setShowFullText] = useState(false);

  return (
    <div className='comparatore'>
        <div className='comparatore-top'>
            <div>
                {!isMobile() && <img alt='icon' src={icon} />}
                <h2 style={{fontWeight: '400', lineHeight: '28px'}}>Confronta le <b>migliori università</b> online</h2>
            </div>
            <div>
                <img alt='sequenza comparatore' src={comp1} />
            </div>
        </div>
        <h1 className='h1-comp'>In pochi click trovi il <b>tuo corso ideale</b> al <font color='#FF6600'><b>costo più basso</b></font> garantito.</h1>
        {/*<h2 className='h2-comp'><b>Iscriviti tramite noi al prezzo più basso garantito. comparacorsi.it è il portavoce di tutte le università telematiche italiane riconosciute dal miur.</b></h2>*/}
        <div className='comparatore-domande' id='form-comparatore-old'>
            <div className='domanda'>
                <label>Quale tipologia di corso di laurea ti interessa?</label>
                <select
                id='course-type-select'
                className={`${degreeType !== "" ? 'filled' : missingField.includes("Tipo di laurea") ? 'campo-mancante' : ''}`} 
                value={degreeType} 
                onChange={(e) => {handleChange(e, setDegreeType); handleChange(e, setOrigDegreeType)}} required>
                    <option disabled value="">Seleziona</option>
                    <option value="Laurea Triennale">Laurea Triennale</option>
                    <option value="Laurea Magistrale">Laurea Magistrale</option>
                    <option value="Ciclo Unico">Ciclo Unico</option>
                    <option value="Master 1° livello">Master 1° livello</option>
                    <option value="Master 2° livello">Master 2° livello</option>
                </select>
            </div>
            <div className='domanda'>
                <label>Quale corso di laurea sei interessato?</label>
                {degreeType !== "" ? (
                <select
                id='degree-select'
                className={`${desiredDegree !== "" ? 'filled' : missingField.includes("Corso di laurea") ? 'campo-mancante' : ''}`}
                value={desiredDegree} 
                onChange={(e) => {handleChange(e, setDesiredDegree); handleChange(e, setOrigDesiredDegree)}} required>
                    <option disabled value="">Seleziona un'area</option>
                    {uniqueArea && uniqueArea.map((data, index) => (
                        <option key={index} value={data}>
                            {data}
                        </option>
                    ))}
                </select> 
                ) : (
                <select 
                id='degree-select'
                disabled
                className={`${desiredDegree !== "" ? 'filled' : missingField.includes("Corso di laurea") ? 'campo-mancante' : ''}`}
                value={desiredDegree} 
                onChange={(e) => {handleChange(e, setDesiredDegree); handleChange(e, setOrigDesiredDegree)}} required>
                    <option disabled value="">Seleziona un'area</option>
                    {uniqueArea && uniqueArea.map((data, index) => (
                        <option key={index} value={data}>
                            {data}
                        </option>
                    ))}
                </select>                    
                )}
            </div>
            <div className='domanda'>
                <label>Cosa ti piacerebbe studiare?</label>
                {desiredDegree !== "" ? (
                <select 
                id='study-subject-select'
                className={`${subjectOfInterest !== "" ? 'filled' : missingField.includes("Oggetto di interesse") ? 'campo-mancante' : ''}`}
                value={subjectOfInterest} 
                onChange={(e) => {handleChange(e, setSubjectOfInterest); handleChange(e, setOrigSubjectOfInterest)}} required>
                    <option disabled value="">Seleziona un corso</option>
                    {uniqueCorso && uniqueCorso.map((data, index) => (
                        <option key={index} value={data}>
                            {data}
                        </option>
                    ))}
                    <option value='Non lo so'>
                        Non lo so
                    </option>
                </select>
                ) : (
                <select 
                id='study-subject-select'
                disabled
                className={`${subjectOfInterest !== "" ? 'filled' : missingField.includes("Oggetto di interesse") ? 'campo-mancante' : ''}`}
                value={subjectOfInterest} 
                onChange={(e) => handleChange(e, setSubjectOfInterest)} required>
                    <option disabled value="">Seleziona un corso</option>
                </select>                    
                )}

            </div>
            <div className='domanda domanda-prezzo'>
                <label style={{maxWidth: '330px', width: '100%'}}>Fino a quanto sei disposto a spendere?</label>
                {/*subjectOfInterest !== "" ? (
                <select 
                className={`${budget !== "" ? 'filled' : ''}`} 
                value={budget} 
                onChange={(e) => {handleChange(e, setBudget); handleChange(e, setOrigBudget)}} required>
                    <option disabled value="">Seleziona un costo</option>
                    {uniquePrice && uniquePrice.map((data, index) => (
                        <option key={index} value={data}>
                            {data}
                        </option>
                    ))}
                </select>
                ) : (
                <select 
                disabled
                className={`${budget !== "" ? 'filled' : ''}`} 
                value={budget} 
                onChange={(e) => handleChange(e, setBudget)} required>
                    <option disabled value="">Seleziona un costo</option>
                </select>                    
                )*/}
                <PrezziSlider min={prezzoMin} max={8000} step={500} onChange={handleChangePrezzo} rangeMax={prezzoMax} />
            </div>
            <div className='domanda'>
                <label>Quando vorresti iscriverti?</label>
                <select 
                id='enrollment-date-select'
                className={`${enrollmentTime !== "" ? 'filled' : missingField.includes("Tempo di iscrizione") ? 'campo-mancante' : ''}`} 
                value={enrollmentTime} 
                onChange={(e) => handleChange(e, setEnrollmentTime)} required>
                    <option disabled value="">Seleziona</option>
                    <option value="Il prima possibile">Il prima possibile</option>
                    <option value="Il prossimo semestre">Il prossimo semestre</option>
                    <option value="Il prossimo anno">Il prossimo anno</option>
                    <option value="Solo curioso">Solo curioso</option>
                </select>
            </div>
            <div className='radio-question'>
              <label>Stai già frequentando l'università?</label>
              <div>
                <div onClick={() => setUniversityStatus("Si")} className={universityStatus === "Si" ? 'domanda filled' : missingField.includes("Stato universitario") ? 'campo-mancante domanda' : 'domanda'}>
                    <input name='current-uni' id='current-uni-radio-yes' checked={universityStatus === "Si"} type='radio' value={"Si"} />
                    <label for="current-uni-radio-yes">Si</label>
                </div>
                <div onClick={() => setUniversityStatus("No")} className={universityStatus === "No" ? 'domanda filled' : missingField.includes("Stato universitario") ? 'campo-mancante domanda' : 'domanda'}>
                    <input name='current-uni' id='current-uni-radio-no' checked={universityStatus === "No"} type='radio' value={"No"} />
                    <label for="current-uni-radio-no">No</label>
                </div>
              </div>
            </div>
            <div className='radio-question'>
              <label>Stai già lavorando?</label>
              <div>
                <div onClick={() => setWorkStatus("Si")} className={workStatus === "Si" ? 'domanda filled' : missingField.includes("Stato lavorativo") ? 'campo-mancante domanda' : 'domanda'}>
                    <input name='working' id='working-radio-yes' checked={workStatus === "Si"} type='radio' value={"Si"} />
                    <label for="working-radio-yes">Si</label>
                </div>
                <div onClick={() => setWorkStatus("No")} className={workStatus === "No" ? 'domanda filled' : missingField.includes("Stato lavorativo") ? 'campo-mancante domanda' : 'domanda'}>
                    <input name='working' id='working-radio-no' type='radio' value={"No"} checked={workStatus === "No"} />
                    <label for="working-radio-no">No</label>
                </div>
              </div>
            </div>
            <div className='domanda'>
                <label>Quanto tempo hai da dedicare alla tua formazione?</label>
                <select 
                id='available-time-select'
                className={`${studyTime !== "" ? 'filled' : missingField.includes("Tempo di studio") ? 'campo-mancante' : ''}`} 
                value={studyTime} 
                onChange={(e) => handleChange(e, setStudyTime)} required>
                    <option disabled value="">Seleziona</option>
                    <option value="Massimo 1 ora al giorno">Massimo 1 ora al giorno</option>
                    <option value="1-3h al giorno">1-3h al giorno</option>
                    <option value="+ 3 ore al giorno">+ 3 ore al giorno</option>
                </select>
            </div>
            <div className='domanda'>
                <label>Fai parte di una di queste categorie?</label>
                <select 
                id='category-select'
                className={`${categories !== "" ? 'filled' : missingField.includes("Categorie") ? 'campo-mancante' : ''}`} 
                value={categories} 
                onChange={(e) => handleChange(e, setCategories)} required>
                    <option disabled value="">Seleziona una categoria</option>
                    <option value="Forze armate">Forze armate</option>
                    <option value="Neo Mamme">Neo Mamme</option>
                    <option value="Sindacati">Sindacati</option>
                    <option value="Terzo settore">Terzo settore</option>
                    <option value="Diversamente Abili e SDA">Diversamente Abili e SDA</option>
                    <option value="Nessuna">Nessuna delle precedenti</option>
                </select>
            </div>
            <div className='domanda domanda-input'>
                <label>Nome</label>
                <input
                id='name-input'
                required
                className={`${firstName !== "" ? 'filled' : missingField.includes("Nome") ? 'campo-mancante' : ''}`}
                type="text"
                value={firstName}
                onChange={(e) => handleChange(e, setFirstName)}
                />
            </div>
            <div className='domanda domanda-input'>
                <label>Cognome</label>
                <input
                id='surname-input'
                required
                className={`${lastName !== "" ? 'filled' : missingField.includes("Cognome") ? 'campo-mancante' : ''}`}
                type="text"
                value={lastName}
                onChange={(e) => handleChange(e, setLastName)}
                />
            </div>
            <div className='domanda domanda-input'>
                <label>Email</label>
                <input
                id='email-input'
                required
                className={`${email !== "" ? 'filled' : missingField.includes("Email") ? 'campo-mancante' : ''}`}
                type="email"
                value={email}
                onChange={(e) => handleChange(e, setEmail)}
                />
            </div>
            <div className='domanda domanda-input'>
                <label>Telefono (valido per il codice di verifica)</label>
                <input
                id='phone-input'
                required
                className={`${phone !== "" ? 'filled' : missingField.includes("Numero di telefono") ? 'campo-mancante' : ''}`}
                type="tel"
                value={phone}
                onChange={(e) => handleChange(e, setPhone)}
                />
            </div>
            <div className='domanda-checkbox'>
                <input
                id='privacy-checkbox'
                type='checkbox'
                required
                value={accept}
                onChange={() => setAccept(!accept)}
                className={!accept && missingField.includes("Accetta i termini e le condizioni") ? 'campo-mancante-checkbox' : ''}
                />
                <label>
                Accetto la Normativa <a href='https://www.comparacorsi.it/privacy-policy/'>Privacy</a> e <a href='https://www.iubenda.com/termini-e-condizioni/62720878'>Termini e Condizioni</a>.
                </label>
            </div>
            <div className='domanda-checkbox' style={{textAlign: 'left', display: 'flex', justifyContent:'center', gap: '0.4rem', paddingLeft: '12px'}}>
                <input
                id='data-processing-checkbox'
                type='checkbox'
                required
                value={acceptTreatment}
                onChange={() => setAcceptTreatment(!acceptTreatment)}
                className={!accept && missingField.includes("Accetta i termini e le condizioni") ? 'campo-mancante-checkbox' : ''}
                />
                <label style={{textAlign: 'left'}}>
                Presto il consenso al trattamento dei miei dati personali per comunicarli e ricevere informazioni promozionali da parte di terzi ai sensi dell’art. 2.3 lett. c) dell’<a href='https://www.comparacorsi.it/privacy-policy/'>informativa privacy</a>
                </label>
            </div>
        </div>
        {!isLoad ? 
        <button id='comparatore-old-discover-courses-btn' onClick={handleSubmit} className='button-prosegui'>Scopri tutti i corsi <FaArrowRight /></button> : 
        <button className='button-prosegui'>Invio in corso..</button>
        }
        <p className='p-under-btn'>Verrai contattato da un nostro operatore</p>
        <div className='comparatore-bottom'>
            <h2>
            <b>Compariamo corsi online</b>, e lo facciamo <font color='#FF692D'>bene</font>.
            </h2>
            <p>
            {showFullText ? (
                <>
                  Siamo il primo comparatore di corsi online in Italia. Il nostro obiettivo è quello di fare chiarezza nel mare di offerte formative che trovi in rete: mettiamo a confronto i migliori enti di istruzione telematici e le <b>università telematiche</b> per aiutarti a scegliere l’opzione adatta alle tue esigenze. 
                  Grazie a ComparaCorsi puoi valutare le caratteristiche delle migliori realtà formative italiane online, tra cui: corsi triennali, corsi magistrali, corsi a ciclo unico, corsi post laurea, corsi professionalizzanti, corsi di perfezionamento, corsi di preparazione ai test di ingresso e molti altri. 
                  Con ComparaCorsi, trovi in pochi minuti il percorso formativo che più si avvicina alle tue esigenze, confrontando le proposte delle principali istituzioni, enti e <b>università telematiche</b> italiane fruibili online. Il nostro servizio è gratuito e ti offre una panoramica completa delle opzioni disponibili, 
                  aiutandoti nella scelta del percorso giusto per la tua crescita personale e professionale. Se sei indeciso su quale percorso universitario seguire, la nostra sezione dedicata ai corsi triennali e magistrali ti permetterà di confrontare diversi indirizzi e specializzazioni, dandoti una chiara visione delle opportunità.
                  Se devi superare un concorso e non sai quale corso di preparazione fa al caso tuo, puoi affidarti alla sezione dedicata ai corsi per le professioni. Se vuoi perfezionare le tue competenze dopo la laurea, puoi facilmente mettere a confronto i migliori corsi post laurea e professionalizzanti. Grazie alla nostra piattaforma, 
                  rimani sempre aggiornato sulle ultime novità del mondo della formazione e puoi fare una scelta consapevole, trasparente e in linea con gli obiettivi del tuo futuro.
                </>
              ) : (
                <>
                  Siamo il primo comparatore di corsi online in Italia. Il nostro obiettivo è quello di fare chiarezza nel mare di offerte formative che trovi in rete: mettiamo a confronto i migliori enti di istruzione telematici e le <b>università telematiche</b> per aiutarti a scegliere l’opzione adatta alle tue esigenze. 
                  Grazie a ComparaCorsi puoi valutare le caratteristiche delle migliori realtà formative italiane online, tra cui: corsi triennali, corsi magistrali, corsi a ciclo unico, corsi post laurea, corsi professionalizzanti, corsi di perfezionamento, corsi di preparazione ai test di ingresso e molti altri. 
                </>
              )}
              <button onClick={() => setShowFullText(!showFullText)}>
                {showFullText ? 'Leggi meno' : 'Leggi di più'}
              </button>            
            </p>
        </div>
    </div>
  )
}

export default ComparatoreLeadSystem