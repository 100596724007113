export const atenei = [
    { 
        ateneo: 'San Raffaele',
        online: 'si',
        cfu: 'si',
        numeroCorsi: '50',
        recensioni: '0' ,
        punteggio: '0/5',
        img: '/',
        sedi: 'San Raffaele, 50 sedi dislocate in tutta Italia:',
        info: 'L\'Università San Raffaele Roma, istituita nel 2006, offre un\'ampia gamma di corsi di laurea triennali e magistrali, oltre a master e percorsi di perfezionamento, attraverso un sistema di E-Learning. Questo approccio permette agli studenti di studiare in modo flessibile, superando limitazioni fisiche e geografiche. L\'ateneo è focalizzato sulla formazione personalizzata, con l\'obiettivo di fornire un\'istruzione accessibile e di qualità che si adatta alle esigenze individuali.',
        dopo1: 'Piattaforma con login personale',
        dopo2: 'Iscrizione e Accesso: Dopo l\'iscrizione, gli studenti hanno accesso a un percorso di studio online personalizzato',
        dopo3: 'Flessibilità: Possibilità di seguire lezioni in qualsiasi momento e luogo',
        dopo4: 'Esercitazioni di autovalutazione per monitorare l\'apprendimento',
        dopo5: 'Offerta Formativa: Corsi di laurea triennali, magistrali, master e percorsi di perfezionamento.',
        servizio3: '50 sedi d’esame',
        //dopo6: '',
    },
    {
        ateneo: 'Unipegaso',
        online: 'si',
        cfu: 'si',
        numeroCorsi: '80',
        recensioni: '5.620' ,
        punteggio: '4,5/5',
        img: '/Logo-ECP-Pegaso-2022.png',
        sedi: 'Unipegaso, 80 sedi dislocate in tutta Italia:',
        info: 'L\'Università Pegaso è un\'istituzione di istruzione superiore che offre corsi di laurea e master online. Si distingue per un approccio didattico innovativo, sfruttando tecnologie avanzate per fornire un\'esperienza educativa flessibile e accessibile. Gli studenti hanno accesso a materiali didattici multimediali, supporto personalizzato da tutor e docenti, e possono partecipare a lezioni virtuali. L\'Università è nota per promuovere l\'apprendimento indipendente e l\'accesso all\'istruzione superiore a un pubblico ampio.',
        dopo1: 'Piattaforma con login personale',
        dopo2: 'Accesso a materiali didattici innovativi: learning objects, testi, diapositive audio-commentate, filmati lezioni.',
        dopo3: 'Lezioni, sia in modalità sincrona sia asincrona',
        dopo4: 'Esercitazioni di autovalutazione per monitorare l\'apprendimento',
        dopo5: 'Supporto personalizzato dai tutor e dai titolari di disciplina.',
        dopo6: 'Esami finali svolti in presenza nelle sedi dell\'università',
        servizio3: '74 sedi d’esame',
    },
    {
        ateneo: "Mercatorum",
        online: 'si',
        cfu: 'si',
        numeroCorsi: '67',
        recensioni: '1512' ,
        punteggio: '4,5/5',
        img: '/universita_mercatorum-1-1024x544.png',
        info: 'L\'Università Mercatorum, con sede principale a Roma, si caratterizza come una startup universitaria focalizzata sulla formazione imprenditoriale, fondata attraverso una partnership pubblico-privata unica nel suo genere. L\'ateneo offre corsi online adatti anche a professionisti già occupati, che enfatizzano l\'innovazione e l\'imprenditorialità. I programmi includono strategie aziendali per startup, sviluppo di idee imprenditoriali e raccolta fondi tramite crowdfunding. Il nome "Universitas Mercatorum" riflette la sua connessione storica con le Camere di Commercio, mirando a valorizzare il capitale umano in vari settori economici.',
        sedi: 'Mercatorum, 67 sedi dislocate in tutta Italia:',
        dopo1: 'Piattaforma con login personale',
        dopo2: 'Materiale Didattico Online: Comprende video lezioni, esercitazioni, e risorse ipertestuali',
        dopo3: 'Piattaforme Interattive: Per discussioni e videoconferenze',
        dopo4: 'Tutor Specializzati: Supporto personalizzato per l\'apprendimento',
        dopo5: 'Esperienza Pratica: Collegamenti con il mondo del lavoro tramite il network delle Camere di Commercio',
        dopo6: 'Valutazione: Esami scritti e orali in presenza',
        servizio3: '62 sedi d’esame',
    },
    {
        ateneo: 'eCampus',
        online: 'si',
        cfu: 'si',
        numeroCorsi: '61',
        recensioni: '3552' ,
        punteggio: '4/5',
        img: '/IUL logo 1.png',
        sedi: "eCampus, 59 sedi dislocate in tutta Italia",
        info: 'L\'Università eCampus è un\'istituzione accreditata nel panorama dell\'istruzione superiore online in Italia. Si distingue per un\'ampia offerta formativa che include diversi corsi di laurea, master e formazioni post-laurea, coprendo molteplici aree di studio come economia, ingegneria, psicologia, giurisprudenza, e arti. L\'ateneo pone particolare enfasi sull\'innovazione tecnologica e la flessibilità, permettendo agli studenti di studiare efficacemente da remoto. eCampus è riconosciuta per il suo impegno nel rendere l\'istruzione superiore accessibile e adattabile alle esigenze individuali.',
        dopo1: 'Piattaforma con login personale',
        dopo2: 'Accesso a insegnamenti di docenti esperti e supporto di tutor qualificati.',
        dopo3: 'Possibilità di studiare online in qualsiasi momento e luogo.',
        dopo4: "Programma educativo personalizzato e flessibile.",
        dopo5: 'Offerta di corsi di laurea e master in varie discipline.',
        dopo6: "Opportunità di conseguire la laurea bilanciando studio e altri impegni.",
        servizio3: '59 sedi d’esame',
    },
    {
        ateneo: 'Uninettuno',
        online: 'si',
        cfu: 'si',
        numeroCorsi: '30',
        recensioni: '63' ,
        punteggio: '4,2/5',
        img: '/uninettuno 1.png',
        sedi: "Uninettuno, 27 sedi dislocate in tutta Italia:",
        info: 'L\'Università Telematica Internazionale UNINETTUNO, istituita nel 2005, offre corsi di laurea e master in varie lingue, includendo italiano, inglese e arabo. Si distingue per la sua metodologia di insegnamento a distanza, facilitando l\'accesso all\'istruzione superiore a studenti di tutto il mondo. È anche nota per il suo impegno nella ricerca e nella cooperazione interculturale.',
        dopo1: 'Piattaforma con login personale',
        dopo2: 'Materiali Didattici Digitali: Accesso a libri di testo elettronici, video lezioni, slide e materiale supplementare.',
        dopo3: 'Tutoraggio: Supporto di un team di tutor per assistenza sui contenuti dei corsi.',
        dopo4: 'Esami: Possibilità di sostenere esami nei centri affiliati.',
        dopo5: 'Orientamento e Carriera: Servizi di orientamento, workshop, seminari e consulenze.',
        dopo6: "Community e Attività Extra-Curriculari: Partecipazione a forum, gruppi di studio online e altre attività.",
        servizio3: '27 sedi d’esame',
    },
    {
        ateneo: 'Unimarconi',
        online: 'si',
        cfu: 'si',
        numeroCorsi: '41',
        recensioni: '144' ,
        punteggio: '4/5',
        img: '/IUL logo 1.png',
        sedi: 'Unimarconi, 27 sedi dislocate in tutta Italia:',
        info: 'L\'Università digitale Unimarconi, fondata nel 2004, è stata la prima università telematica riconosciuta in Italia. Offre un\'ampia varietà di corsi di laurea in discipline come Economia, Giurisprudenza, Ingegneria, Lettere, e molte altre, con un approccio flessibile che combina l\'apprendimento online con attività frontali. È conosciuta per la sua accessibilità, con iscrizioni aperte tutto l\'anno e una politica di esami senza test d\'ingresso.',
        dopo1: 'Piattaforma con login personale',
        dopo2: "Materiali Didattici: Accesso a libri di testo digitali, video lezioni, slide e articoli di ricerca, disponibili 24/7 per studio flessibile.",
        dopo3: 'Tutoraggio Online: Assegnazione di un tutor per assistenza e chiarimenti sui corsi, e supporto nella preparazione degli esami.',
        dopo4: 'Esami: gli esami possono essere sostenuti in uno dei centri esame affiliati.',
        dopo5: 'Servizi di Supporto e Attività Extra: Accesso a biblioteca digitale, consulenza per la carriera, seminari online e workshop per arricchire l\'esperienza formativa e il networking.',
        //dopo6: '',
        servizio3: '25 sedi d’esame',
    },
    {
        ateneo: 'Unitelma',
        online: 'si',
        cfu: 'si',
        numeroCorsi: '21',
        recensioni: '0' ,
        punteggio: '0/5',
        img: '/IUL logo 1.png',
        sedi: 'Unitelma, 26 sedi dislocate in tutta Italia:',
        info: 'L\'Università Unitelma Sapienza, fondata nel 2004 e basata a Roma, è gestita da un consorzio che include la Sapienza Università di Roma. Offre corsi di laurea e master in modalità online, con esami svolti nelle diverse sedi. L\'ateneo si concentra su aree come Giuridica, Economica, Informatica, Psicologia e Archeologia, fornendo anche supporto nella preparazione professionale degli studenti.',
        dopo1: 'Piattaforma con login personale',
        dopo2: 'Materiale Didattico Online: Accesso a video lezioni, documenti, slide e risorse interattive per uno studio flessibile.',
        dopo3: 'Supporto Tutor e Docenti: Assistenza attraverso tutoraggio online, forum di discussione e chiarimenti sui corsi.',
        dopo4: 'Esami: verrai valutato con esami scritti o orali in presenza nelle diverse sedi.',
        dopo5: 'Servizi Studenteschi e Orientamento: Supporto con orientamento accademico e professionale, seminari e workshop.',
        dopo6: 'Networking e Attività Extra-Curriculari: Partecipazione a webinars, progetti di gruppo online e forum per sviluppare reti professionali e apprendimento collaborativo.',
        servizio3: '26 sedi d’esame',
    },
    {
        ateneo: 'Unifortunato',
        online: 'si',
        cfu: 'si',
        numeroCorsi: '8',
        recensioni: '0' ,
        punteggio: '0/5',
        img: '/universita_giustinofortunato-1-1024x544.png',
        sedi: 'Unifortunato, 5 sedi dislocate in tutta Italia:',
        info: 'L\'Università Unifortunato, istituita nel 2006, offre un\'ampia gamma di corsi di laurea online in discipline come Economia, Giurisprudenza, Ingegneria, Psicologia e Scienze dell\'Educazione. È una università con un focus sulla valorizzazione personale e responsabilità sociale. ',
        dopo1: 'Piattaforma con login personale',
        dopo2: 'Materiali Didattici Digitali: Accesso a video lezioni, documenti, presentazioni e materiali interattivi per studiare a tuo ritmo.',
        dopo3: 'Supporto di Tutor e Docenti: Assistenza continua da tutor e docenti, inclusi sessioni di tutoraggio online per chiarimenti sui corsi.',
        dopo4: 'Esami: verrai valutato con esami scritti o orali in presenza nelle diverse sedi.',
        dopo5: 'Servizi di Orientamento e Carriera: Offerta di orientamento professionale, seminari, workshop e consulenze individuali.',
        dopo6: 'Attività Extra-Curriculari e Comunità Studentesca: Avrai l\'opportunità di partecipare a forum online, gruppi di studio e altre attività.',
        servizio3: '5 sedi d’esame',
    },
    {
        ateneo: 'Unicusano',
        online: 'si',
        cfu: 'si',
        numeroCorsi: '7',
        recensioni: '387' ,
        punteggio: '3,1/5',
        img: '/universita_cusano-1-1024x544.png',
        sedi: 'Unicusano, 38 sedi dislocate in tutta Italia:',
        info: 'L\'Università Unicusano, fondata nel 2006, è un\'istituzione di istruzione superiore che si distingue nel campo dell\'e-learning. Offre un\'ampia gamma di corsi di laurea, includendo aree come Economia, Psicologia, Ingegneria, Giurisprudenza e Scienze della Comunicazione, nonché master e corsi di perfezionamento, con una modalità didattica flessibile che combina lezioni online e in presenza.',
        dopo1: 'Piattaforma con login personale',
        dopo2: 'Materiali Didattici Digitali: Disponibilità di video lezioni, testi e slide per uno studio autonomo.',
        dopo3: 'Tutoraggio e Supporto Accademico: Servizio di tutoraggio per assistenza sui corsi e preparazione esami.',
        dopo4: 'Esami: verrai valutato con esami scritti o orali in presenza nelle diverse sedi',
        dopo5: 'Orientamento e Carriera: Servizi di orientamento professionale, seminari e workshop.',
        servizio3: '38 sedi d’esame',
        dopo6: 'Attività Extra-Curriculari: Opportunità di partecipare a forum online e progetti di gruppo per l\'interazione e l\'apprendimento collaborativo.',
    },
    {
        ateneo: 'Iul',
        online: 'si',
        cfu: 'si',
        numeroCorsi: '30',
        recensioni: '0' ,
        punteggio: '0/5',
        img: '/IUL logo 1.png',
        sedi: 'IUL, 38 sedi dislocate in tutta Italia:',
        info: 'L\'Università IUL, istituita nel 2005 e con sede principale a Firenze, offre corsi universitari online come lauree triennali e magistrali, master e corsi di perfezionamento. Si concentra principalmente sulla formazione continua per il personale della scuola, promuovendo un approccio di apprendimento permanente. L\'offerta formativa include aree come le scienze sociali, l\'economia, la linguistica e l\'innovazione tecnologica, con esami sostenuti in varie sedi in Italia.',
        dopo1: 'Piattaforma con login personale',
        dopo2: 'Materiali Didattici Digitali: Accesso a video, testi, slide e materiali interattivi per studio flessibile e autonomo.',
        dopo3: 'Supporto di Tutor e Docenti: Sarai seguito da tutor e docenti per tutto il corso dei tuoi studi.',
        dopo4: 'Esami: verrai valutato con esami scritti o orali in presenza nelle diverse sedi.',
        dopo5: 'Servizi di Orientamento e Carriera: Servizi di orientamento e consulenza professionale, seminari e workshop per definire e raggiungere obiettivi accademici e professionali.',
        dopo6: 'Attività Extra-Curriculari e Comunità Studentesca: Promozione di attività come forum online e progetti di gruppo per collaborazione e interazione studentesca.',
        servizio3: '38 sedi d’esame',
    },
    {
        ateneo: 'Unidav',
        online: 'si',
        cfu: 'si',
        numeroCorsi: '3',
        recensioni: '0' ,
        punteggio: '0/5',
        img: '/Unidav 1.png',
        sedi: 'Unidav, 12 sedi dislocate in tutta Italia:',
        info: 'L\'Università Unidav è stata fondata il 27 ottobre 2004 e rappresenta una delle prime università telematiche in Italia. È un\'istituzione non statale, nata dalla collaborazione tra l\'Università degli Studi "Gabriele d\'Annunzio" e la Fondazione Ud\'A (Fondazione Università Gabriele D\'Annunzio), operante esclusivamente online. L\'offerta formativa di Unidav è diversificata, includendo corsi di laurea triennale e magistrale, dottorati di ricerca, corsi di alta formazione professionale, corsi di preparazione ai test di ingresso e formazione specifica per il mondo della scuola.',
        dopo1: 'Piattaforma con login personale',
        dopo2: 'Materiali Didattici Digitali: Avrai a disposizione video lezioni, testi e slide per studiare autonomamente.',
        dopo3: 'Supporto Accademico: Beneficerai di supporto da tutor e docenti per domande sui corsi e preparazione esami.',
        dopo4: 'Esami: verrai valutato con esami scritti o orali in presenza nelle diverse sedi.',
        dopo5: 'Orientamento e Carriera: Unidav offre servizi di orientamento professionale, seminari e workshop.',
        dopo6: 'Attività Extra-Curriculari: Potrai partecipare a forum online e progetti di gruppo per l\'interazione studentesca.',
        servizio3: '12 sedi d’esame',
    }
]

export const ateneiTech = [
    { 
        ateneo: 'MUSA FORMAZIONE',
        online: 'si',
        recensioni: '60' ,
        punteggio: '4,7/5',
    },
    {
        ateneo: 'AULAB',
        online: 'si',
        recensioni: '254' ,
        punteggio: '4,4/5',
    },
    {
        ateneo: "EPICODE",
        online: 'si',
        recensioni: '153' ,
        punteggio: '4,6/5',
    },
    {
        ateneo: 'START2IMPACT',
        online: 'si',
        recensioni: '250' ,
        punteggio: '4,7/5',
    },
    {
        ateneo: 'Executy',
        online: 'si',
        recensioni: '0' ,
        punteggio: '0/5',
    },
    {
        ateneo: 'TTF',
        online: 'si',
        recensioni: '0' ,
        punteggio: '0/5',
    },
    {
        ateneo: 'DIGITAZON',
        online: 'si',
        recensioni: '69' ,
        punteggio: '4,5/5',
    }
]